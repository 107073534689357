import { gql } from "@apollo/client";

export const GET_HOTEL_PROXIMITY = gql`
  query hotels($proximity: ProximityInput!) {
    searchHotelsByDistance(proximity: $proximity) {
      statusCode
      message
      results {
        crsHotelCode
        name
        rangeInMilesFromOrigin
        address {
          country
          region
        }
      }
    }
  }
`;

export const SEARCH_HOTEL_BY_INTEREST = gql`
  query searchHotelsByInterest($proximity: ProximityInput!) {
    searchHotelsByInterest(proximity: $proximity) {
      message
      statusCode
      results {
        type
        hotelId
        hotelCode
        crsHotelCode
        crsName
        coordinates {
          latitude
          longitude
        }
        address
        amenities
        brand {
          name
          code
          logoUrl
        }
        gallery
        galleryImages {
          url
          alt
        }
        url
        tags
        tagline
        name
        phone
        amenities
        amenityLabels
        rangeInMilesFromOrigin
      }
    }
  }
`;

export const GET_HOTEL_AVAILABILITY = gql`
  query getHotelAvailability($input: HotelAvailInput!) {
    hotelAvailablity(hotelAvailInput: $input) {
      statusCode
      message
      warnings
      queryHotel {
        id
        name
        imageUrl
        dataSourceType
        hotelTier
        hotelLocation
        redemptionItem {
          redemptionItemId
          redemptionItemCode
          currencyRequired
          typeCode
          description
        }
        brand {
          name
          code
          logoUrl
        }
        amenities {
          name
          machineName
        }
        services {
          code
          name
          quantity
          rate
          title
          description
          pricingType
          taxes
          total
        }
        rooms {
          code
          accessible
          name
          imageUrls
          description
          occupancy
          amenities
          quantity
          roomRates {
            rateCode
            currencyCode
            roomRate
            roomRateNightly {
              expireDate
              effectiveDate
              rate
            }
            roomRateWithFees
            subTotal
            subTotalWithFees
            taxes
            fees
            resortFee
            taxItems {
              amount
              effectiveDate
              expireDate
              chargeFrequency
              name
              description
            }
            feeItems {
              amount
              effectiveDate
              expireDate
              chargeFrequency
              name
              description
            }
            total
            name
            advanced
            description
            guarantee {
              guaranteeCode
              description
            }
            cancelPolicy {
              policyCode
              description
              absoluteDeadline
            }
            rateType
            planType
            isBaseRate
            isPromotionalRate
            isRedemptionRate
            ratePlanID
            sort
          }
        }
      }
    }
  }
`;

export const GET_HOTEL_DETAILS = gql`
  query hotel($id: String!) {
    hotel(id: $id) {
      id
      name
      brand {
        code
        name
      }
      checkInTime
      checkOutTime
      cmsHotelCode
      cmsHotelId
      name
      geoCoordinate {
        latitude
        longitude
      }
      rooms {
        checkInTime
        checkOutTime
        code
        name
        occupancy
        imageUrls
        description
        amenities
      }
    }
  }
`;

export const GET_LOCATION_PROXIMITY = gql`
  query searchLocations_proximity($input: LocationSearchInput!) {
    searchLocations(locationSearchInput: $input) {
      results {
        uniqueIdentifier
        name
        locationType
        rangeInMilesFromOrigin
        city
        state
        url
        coordinates {
          latitude
          longitude
        }
        hotelCount
      }
      message
      statusCode
    }
  }
`;

export const GUEST_LOGIN_QUERY = gql`
  query Auth($username: String!, $password: String!) {
    auth0TokenInfo(userName: $username, password: $password) {
      lastEditDate
      message
      statusCode
      token
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($newPassword: String!, $args: String!) {
    completeSignUpProcess(
      updateUserProfileRequest: { newPassword: $newPassword, args: $args }
    ) {
      message
      statusCode
      email
    }
  }
`;

export const COMPLETE_SIGN_UP_PROCESS_MUTATION = gql`
  mutation CompleteSignUpProcess(
    $updateUserProfileRequest: UpdateUserProfileRequestInput!
  ) {
    completeSignUpProcess(updateUserProfileRequest: $updateUserProfileRequest) {
      message
      statusCode
      email
    }
  }
`;

export const SEND_RESET_PASSWORD_EMAIL_MUTATION = gql`
  mutation ResetPassword_SendEmail($email: String!) {
    createResetPasswordEmail(email: $email) {
      message
      statusCode
    }
  }
`;

export const SEND_ONE_LOGIN_EMAIL_MUTATION = gql`
  query passwordlessLink($email: String!) {
    passwordlessLink(email: $email) {
      message
      statusCode
    }
  }
`;

export const SIGN_UP_MUTATION = gql`
  mutation SignUp(
    $email: String!
    $firstName: String!
    $lastName: String!
    $enrollment: EnrollmentInput
  ) {
    insertOrUpdateUserProfile(
      updateUserProfileRequest: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        enrollment: $enrollment
      }
    ) {
      message
      statusCode
    }
  }
`;

export const SIGN_UP_WIFI_MUTATION = gql`
  mutation SignUp_Wifi(
    $email: String!
    $firstName: String!
    $lastName: String!
    $hotelName: String!
  ) {
    insertOrUpdateUserProfile(
      updateUserProfileRequest: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        source: "wifi"
        enrollment: { hotel_name: $hotelName }
      }
    ) {
      message
      statusCode
    }
  }
`;

export const CHECK_USER_BY_EMAIL_QUERY = gql`
  query getUserByEmail($email: String!) {
    userByEmail(email: $email) {
      userId
      userLoginId
      entryDate
      lastEditDate
      statusCode
      message
      activatedGuest
    }
  }
`;

export const CANCEL_RESERVATION_MUTATION = gql`
  mutation cancelReservation($confirmNumber: String!) {
    cancelReservation(confirmNumber: $confirmNumber) {
      cancellationId
      cancellationAmount
      cancellationStatus
      currencyCode
      reservationId
      statusCode
      statusMessage
    }
  }
`;

export const GET_RESERVATION_DETAILS_QUERY = gql`
  query getReservation($confirmationNumber: String!, $surname: String!) {
    reservation(confirmationnumber: $confirmationNumber, surname: $surname) {
      statusCode
      statusMessage
      start
      end
      membershipId
      isCancellable
      guests {
        givenName
        surname
        phoneNumber
        email
      }
      count {
        adult
        child
      }
      cancelId
      chainCode
      iD
      status
      resSpecialRequest
      roomTypeCode

      hotel {
        name
        id
        cmsHotelCode
        geoCoordinate {
          latitude
          longitude
        }
      }
      hotelAddress {
        cityName
        locality
        region
        streetAddress1
        streetAddress2
        postalCode
        country
      }
      policies {
        checkInTime
        checkOutTime
      }
      cancelPolicies {
        policyCode
        description
        penaltyIncludesTax
        penaltyAmount
        penaltyCurrencyCode
        offsetDropTime
        offsetTimeUnit
        offsetUnitMultiplier
        numberOfNights
      }
      roomRates {
        roomRate
        roomRateWithFees
        subTotal
        subTotalWithFees
        taxes
        fees
        total
        upgrades
        effectiveDate
        expireDate
        currencyCode
        taxItems {
          amount
          effectiveDate
          expireDate
          chargeFrequency
          name
          description
        }
        feeItems {
          amount
          effectiveDate
          expireDate
          chargeFrequency
          name
          description
        }
      }

      ratePlanCode
      promotionCode
      ratePlanAccrualIndicator
      ratePlanName
      ratePlanPriceViewableInd

      cards {
        cardCode
        cardExpiryDate
        cardHolderName
        cardNumber
        cardType
      }
      roomDescription {
        name
        description
        shortDescription
        occupancy
        amenities
      }
      services {
        description
        serviceInventoryCode
        san
        quantity
        price {
          rate
          taxes
          total
        }
      }
    }
  }
`;

export const BRAND_FILTER_QUERY = gql`
  query searchHotelsBrandsByHotelCodes($hotelCrsCodes: [String!]!) {
    searchHotelsBrandsByHotelCodes(hotelCrsCodes: $hotelCrsCodes) {
      message
      statusCode
      results {
        count
        brandCode
        name
      }
    }
  }
`;

export const GET_RECENT_SEARCH_QUERY = gql`
  query GET_RECENT_SEARCH($memberId: String!) {
    abandonCartbyMemberID(memberID: $memberId) {
      abandonCart {
        memberID
        lastUpdated
        startDate
        endDate
        ratePlanFilterCode
        hotelID
        occupancy
        roomType
        promoCode
        groupCode
      }
    }
  }
`;

export const POST_RECENT_SEARCH_QUERY = gql`
  mutation POST_RECENT_SEARCH(
    $memberID: String!
    $startDate: DateTime!
    $endDate: DateTime!
    $lastUpdated: DateTime!
    $hotelID: String!
    $roomType: String
    $promoCode: String
    $occupancy: String
    $groupCode: String
    $ratePlanFilterCode: String
  ) {
    insertAbandonCartMember(
      input: {
        memberID: $memberID
        hotelID: $hotelID
        endDate: $endDate
        startDate: $startDate
        lastUpdated: $lastUpdated
        occupancy: $occupancy
        promoCode: $promoCode
        ratePlanFilterCode: $ratePlanFilterCode
        groupCode: $groupCode
        roomType: $roomType
      }
    ) {
      message
      statusCode
    }
  }
`;

export const SEARCH_HOTELS_AMENITIES_QUERY = gql`
  query searchHotelsAmenitiesByHotelCodes($hotelCodes: [String!]!) {
    searchHotelsAmenitiesByHotelCodes(hotelCrsCodes: $hotelCodes) {
      statusCode
      message
      results {
        id
        name
        machineName
        count
      }
    }
  }
`;

export const GET_LOCATION_INTERESTS = gql`
  query searchLocationInterests($locationSearchInputUrl: String!) {
    searchLocationInterests(locationSearchInputUrl: $locationSearchInputUrl) {
      message
      statusCode
      interests {
        title
        url
      }
    }
  }
`;

export const GET_HOTEL_AUTOCOMPLETE_SUGGESTIONS = gql`
  query GetHotelAutoCompleteSuggestions($partialName: String!) {
    searchHotelsByPartialName(partialName: $partialName) {
      message
      statusCode
      results {
        name
        hotelCode
        administrativeArea
      }
    }
  }
`;
