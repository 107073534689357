import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

// Define the shape of the context value
interface MobileDetectContextType {
  isMobileOnly: boolean;
}

// Create the context with default undefined to enforce provider use
const MobileDetectContext = createContext<MobileDetectContextType | undefined>(
  undefined
);

// Define the provider's prop type
interface MobileDetectProviderProps {
  children: ReactNode;
}

// The MobileDetectProvider component
export const MobileDetectProvider = ({
  children,
}: MobileDetectProviderProps) => {
  const [isMobileOnly, setIsMobileOnly] = useState<boolean>(false);

  useEffect(() => {
    const updateMobileState = () => {
      setIsMobileOnly(window.innerWidth < 992);
    };

    // Initialize the state and add resize event listener
    updateMobileState();
    window.addEventListener("resize", updateMobileState);

    return () => {
      window.removeEventListener("resize", updateMobileState);
    };
  }, []);

  return (
    <MobileDetectContext.Provider value={{ isMobileOnly }}>
      {children}
    </MobileDetectContext.Provider>
  );
};

// Custom hook to use the MobileDetectContext
export const useMobileDetectContext = () => {
  const context = useContext(MobileDetectContext);
  if (!context) {
    throw new Error(
      "useMobileDetectContext must be used within a MobileDetectProvider"
    );
  }
  return context;
};
