import { formatDateGAPI } from "../dates";
import { store } from "../../redux/store";
import { Guest, Room, InitiatePayload } from "./types";
import { TP_REDEMPTION_RATE } from "../../@types/Constants";
import { getCRSSubSource } from "../../utils/helpers";

export function createInitiateReservationPayload(
  roomIds: string[],
  updatedCheckout: {
    HotelCode: string;
    Start: string;
    End: string;
    GuestDetails: Guest;
    groupCode?: string;
    promotionCode?: string;
    Rooms: Record<string, Room>;
    Brand?: string | null;
    hotelLocation?: string | null;
    redemptionItem?: any;
  },
  profileType = "Bronze"
): InitiatePayload {
  const memberState = store.getState().member;
  let isRedemptionRate;
  const roomsList = roomIds.map((roomId) => {
    const room = updatedCheckout.Rooms[roomId];
    if (TP_REDEMPTION_RATE.includes(room.rate.RateCode)) {
      isRedemptionRate = true;
    }
    return {
      count: {
        adult: room.adults,
        child: {
          numGuests: room.children,
          ages: room.childrenAges
            ? room.childrenAges.map((age) => {
                if (age === "") {
                  return 0; // Convert empty string to 0
                } else {
                  return Number(age); // Convert string to number
                }
              })
            : [],
        },
      },
      roomId: roomId,
      roomTypeCode: room.room.RoomCode,
      ratePlanCode: room.rate.RateCode,
      roomRates: room.rate.roomRateNightly.map(
        (nightlyInfo: { expireDate: any; effectiveDate: any; rate: any }) => {
          return {
            expireDate: nightlyInfo.expireDate,
            effectiveDate: nightlyInfo.effectiveDate,
            amountBeforeTax: nightlyInfo.rate,
          };
        }
      ),
    };
  });
  const guestId = updatedCheckout?.GuestDetails?.id
    ? updatedCheckout?.GuestDetails?.id
    : memberState.isLoggedIn && isRedemptionRate
    ? memberState.crmBasicProfile?.memberId
    : "";
  const membershipId = memberState.isLoggedIn
    ? memberState.crmBasicProfile?.memberId
    : updatedCheckout?.GuestDetails?.id
    ? updatedCheckout?.GuestDetails?.id
    : "";
  const subSource = getCRSSubSource();
  const rooms = updatedCheckout.Rooms
    ? Object.values(updatedCheckout.Rooms)
    : [];
  const currency = rooms && rooms.length ? rooms[0].rate?.currencyCode : "USD";
  const initiatePayload: InitiatePayload = {
    hotelId: updatedCheckout.HotelCode,
    startDate: updatedCheckout.Start
      ? formatDateGAPI(updatedCheckout.Start)
      : "",
    endDate: updatedCheckout.End ? formatDateGAPI(updatedCheckout.End) : "",
    numberOfUnits: 1,
    guestList: [
      {
        firstName: updatedCheckout.GuestDetails.firstName,
        lastName: updatedCheckout.GuestDetails.lastName,
        email: updatedCheckout.GuestDetails.email,
        membershipId: membershipId,
        phoneNumber: updatedCheckout.GuestDetails.phoneNumber || "",
        isPrimary: true,
      },
    ],
    roomsList,
    emailTemplate: isRedemptionRate ? "TPRedeem" : "Guestconf",
    currencyCode: currency,
    groupCode: updatedCheckout.groupCode || "",
    promotionCode: isRedemptionRate ? "" : updatedCheckout.promotionCode || "",
    ...(subSource && { subSource: subSource }),
    guestId: guestId,
    signupAsMember: updatedCheckout?.GuestDetails?.helloRewardsSignup
      ? updatedCheckout.GuestDetails.helloRewardsSignup
      : false,
    programId: "Travel Pass",
    loyalLevel: profileType,
    RedemptionItemCode:
      updatedCheckout?.redemptionItem?.redemptionItemCode || "",
    HotelLocation: updatedCheckout?.hotelLocation,
    Brand: updatedCheckout?.Brand,
  };

  return initiatePayload;
}

export default createInitiateReservationPayload;
